import {useEffect, useState} from "react"
import {FormProvider, RegisterOptions, useFieldArray, useForm} from "react-hook-form"
import {CustomFieldSetFilterList} from "src/pages/settings/custom-field/field-set-filter"
import {FormControlEntity} from "src/components/shared/inputs/form-control-entity"
import {InventorySetModel} from "src/models/manager/inventory/inventory-set.model"
import {CustomFieldOption} from "src/models/setting-config"
import settingService from "src/services/settings.service"
import {map} from "rxjs"
import "./styles.scss"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"

export interface Params {
  name: string
  required?: boolean
  params?: RegisterOptions<any, any>
}

export const OrderDetailSetFilterComponent = ({
  set,
  count = 1,
  onSubmit
}: {
  set: InventorySetModel
  count: number
  onSubmit: (payload: Record<string, any>) => void
}) => {
  const {t} = useTranslation()
  const [manualOptions, setManualOptions] = useState<Record<string, CustomFieldOption>>()
  const form = useForm<{
    list: Array<{count: number; filter: Record<string, any>}>
  }>({
    defaultValues: {list: [{count, filter: {}}]}
  })

  const filters = useFieldArray({control: form.control, name: "list"})

  const onAdd = () => filters.append({count: 1, filter: []})
  const onDelete = (index: number) => filters.remove(index)

  const getOptions = async (groups: number[]) => {
    const filterOptions = await Promise.all(groups.map((group_id) => settingService.getInventoryOptions({group_id})))
    const _options = groups.map((group_id, i) => [`${group_id}`, filterOptions[i]])
    setManualOptions(Object.fromEntries(_options))
  }

  const [customFields, setCustomFields] = useState<string[]>([])
  const overallCount = form.watch("list").reduce((p, form) => p + +form.count, 0)

  useEffect(() => {
    const sub = settingService.config$
      .pipe(map((config) => config.custom_fields.inventory.filter((field) => field.filter)))
      .subscribe((fields) => setCustomFields(fields.map((field) => field.name)))
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const groups = set.items.map((item) => item.group_id)
    getOptions(groups)
  }, [])

  const items =
    manualOptions &&
    set.items.filter((item) => {
      const objects = Object.keys(manualOptions[item.group_id])
        .filter((k) => customFields.includes(k))
        .filter((k) => manualOptions[item.group_id][k].length > 1)
      return objects.length > 0
    })

  return (
    manualOptions && (
      <FormProvider {...form}>
        <div className="flex gap-2 justify-between items-end mb-3">
          <h4 className="mb-0">{set.name}</h4>
          <div className="color-gray-500 text-sm mt-4">
            {t("orders.inventory.modals.filter_modal.total_amount")}: {overallCount}
          </div>
        </div>

        {filters.fields.map((filter, index) => (
          <div key={filter.id}>
            <div className="color-gray-500 text-sm mb-2">
              {t("orders.inventory.modals.filter_modal.set")} {index + 1}
            </div>
            <div className="card flex gap-2 mb-3">
              <div className="set-filter-grid">
                {items.map((field) => (
                  <div>
                    <label className="overflow line-clamp-1 text-[13px] color-gray-400 mb-1">{field.group_name}</label>
                    <CustomFieldSetFilterList
                      manualOptions={manualOptions[field.group_id]}
                      key={`${field.id}_${index}`}
                      prefix={`list.${index}.filter.${field.id}.extra__`}
                    />
                  </div>
                ))}
              </div>
              <FormControlEntity
                name={`list.${index}.count`}
                maxcount={100}
                rootclassname="flex justify-end align-self-end"
              />
              <button onClick={() => onDelete(index)} className="remove">
                <Icon icon="add" />
              </button>
            </div>
          </div>
        ))}

        <button className="btn btn-black-outline w-full mb-3 gap-1" onClick={onAdd}>
          <Icon icon="add" />
          {t("common.add")}
        </button>

        <div className="flex justify-end">
          <button
            className="btn btn-primary btn-color-white"
            disabled={filters.fields.length === 0}
            onClick={form.handleSubmit(onSubmit)}>
            {t("common.add")}
          </button>
        </div>
      </FormProvider>
    )
  )
}
