import {useContext, useMemo} from "react"
import {CustomField, CustomFieldOption} from "src/models/setting-config"
import {CustomFieldFilterChoice} from "./field-filter-choice"
import {ConfigContext} from "src"
import {isNil} from "src/utils/isNil"

export function CustomFieldSetFilterList({manualOptions, prefix}: {manualOptions: CustomFieldOption; prefix: string}) {
  const {settings} = useContext(ConfigContext)
  const customFields = useMemo(() => settings.custom_fields.inventory.filter((field) => field.filter), [settings])

  const filterAvailable =
    customFields.length > 0 &&
    manualOptions &&
    customFields.filter(
      (field) => manualOptions[field.name]?.filter((v) => !isNil(v[`extra__${field.name}`])).length > 0
    ).length > 0

  return (
    filterAvailable && (
      <div className="flex gap-2" style={{position: "relative"}}>
        {customFields.map((field: CustomField) => (
          <CustomFieldFilterChoice
            key={field.name}
            type={field.type}
            field={`extra__${field.name}`}
            selectorProps={{menuPortalTarget: document.body}}
            params={{
              name: `${prefix}${field.name}`,
              placeholder: field.label,
              required: field.required,
              params: {required: field.required},
              rootclassname: "col",
              options: manualOptions[field.name].filter((v) => !isNil(v[`extra__${field.name}`]))
            }}
          />
        ))}
      </div>
    )
  )
}
