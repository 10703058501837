import {firstValueFrom, of} from "rxjs"
import {components, GroupBase, OptionProps} from "react-select"
import FormSelectAsync from "src/components/shared/inputs/form-select-async.list"
import {LabelModel} from "src/models/label"
import {CustomFieldType, optionlessFieldTypes} from "src/models/setting-config"
import {FieldParams} from "./utils"
import {isNil} from "src/utils/isNil"
import {Props} from "react-select"
import FormSelect from "src/components/shared/inputs/form-select"
import i18n from "i18next"
import {useTranslation} from "react-i18next"

interface ExtendedLabelModel extends LabelModel {
  cnt?: number
}

interface CustomFieldChoiceConfig {
  type: CustomFieldType
  params: FieldParams
  field: string
  onChange?: (val: any) => void
  selectorProps?: Props
}

function CustomFieldFilterChoiceOption(props: OptionProps<ExtendedLabelModel, boolean, GroupBase<ExtendedLabelModel>>) {
  return (
    <components.Option {...props}>
      <div className="flex justify-between items-center">
        <span>{props.data.label}</span>
        {props.data.cnt && (
          <span>
            {props.data.cnt} {i18n.t("common.amount_suffix")}
          </span>
        )}
      </div>
    </components.Option>
  )
}

export function CustomFieldFilterChoice(config: CustomFieldChoiceConfig) {
  const {t} = useTranslation()
  if (config.type === "boolean") {
    const options: LabelModel[] = [
      {id: "true", label: t("common.boolean.true")},
      {id: "false", label: t("common.boolean.false")}
    ]
    return (
      <FormSelect<LabelModel>
        className="col"
        name={config.params.name}
        label={config.params.label}
        placeholder={config.params.placeholder}
        required={config.params.required}
        options={options as any}
        getOptionValue={(option: LabelModel) => (option ? option.id.toString() : "")}
        getOptionLabel={(option: LabelModel) => (option ? option.label : "")}
        isClearable={true}
        isSearchable={false}
        {...config.selectorProps}
      />
    )
  }
  if (config.params.options && config.params.options.length > 0 && !optionlessFieldTypes.includes(config.type)) {
    return (
      <FormSelectAsync<ExtendedLabelModel>
        className="col"
        name={config.params.name}
        label={config.params.label}
        placeholder={config.params.placeholder}
        required={config.params.required}
        params={{required: config.params.required}}
        components={{Option: CustomFieldFilterChoiceOption}}
        listOptions={() =>
          of(
            config.params.options.map((item: string | any) => ({
              id: typeof item === "string" ? item : item[config.field],
              label: typeof item === "string" ? item : item[config.field],
              cnt: typeof item === "string" ? undefined : item["cnt"]
            }))
          )
        }
        onChange={!isNil(config.onChange) ? config.onChange : undefined}
        getValue={(val: string) => firstValueFrom(of({id: val, label: val}))}
        getOptionLabel={(option: ExtendedLabelModel) => (option ? String(option.label) : null)}
        getOptionValue={(option: ExtendedLabelModel) => (option ? String(option.id) : null)}
        isClearable={true}
        isSearchable={false}
        {...config.selectorProps}
      />
    )
  }
  return <></>
}
